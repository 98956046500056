<template>
  <layout :loading="loading">
    <template v-slot:content>
     <div class="flex flex-col gap-5">
        <t-alert class="my-2" :variant="alert.type" :show="!!alert.message">
          {{ alert.message }}
        </t-alert>
       <h2 class="p-5 pl-0 text-xl border-b border-gray-300">{{ $t('cancel_reason_new_cancel_reason') }}</h2>
       <form class="ml-5 md:ml-10 flex flex-col gap-2" @submit="onSubmit">
         <div class="flex items-center gap-4">
          <label for="description" class="w-20 font-semibold">{{ $t('cancel_reason_description') }}</label>
          <t-input id="description" v-model="newCancelReasonDescription"></t-input>
         </div>
         <t-button variant="primary" class="ml-24 w-24 mt-2">{{ $t('edit_save') }}</t-button>
       </form>
       <h2 class="p-5 pl-0 text-xl border-b border-gray-300">{{ $t('cancel_reason') }}</h2>
       <table class="ml-5 md:ml-10 border border-gray-300 border-collapse">
         <thead>
           <th class="text-left border border-gray-300 p-2">{{ $t('cancel_reason_description') }}</th>
           <th class="border border-gray-300"></th>
         </thead>
         <tbody>
           <tr v-for="(cancelReason, index) in cancelReasons" :key="index">
             <td class="border border-gray-300 p-2 border-collapse"> {{ cancelReason.description }}</td>
             <td class="border border-gray-300 p-2 border-collapse">
               <t-button variant="danger" class="m-auto flex items-center justify-center w-6 h-8 text-center"
                @click="removeCancelReasonById(cancelReason.id)">
                <i class="fas fa-times text-white"></i>
               </t-button>
             </td>
           </tr>
         </tbody>
       </table>
     </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { get, create, remove } from "@/services/cancelreason-service";
import { reject } from "lodash";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    alert: {
      type: 'info',
      message: null
    },
    newCancelReasonDescription: null,
    cancelReasons: [],
  }),
  async mounted() {
    this.loading = true;
    try {
      const { data } = await get();
      this.cancelReasons = data;
    } catch (error) {
      console.log('DEBUG: mounted error', error);
    }
    this.loading = false;
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.alert = {
        type: 'info',
        message: null
      }
      const { isOk } = await this.$dialog.confirm(
        this.$i18n.t('are_you_sure'),
        "",
        "info"
      );

      if (!isOk) {
        return
      }

      this.loading = true;
      try {
        const { data: cancelReason } = await create(this.newCancelReasonDescription);
        this.cancelReasons = [
          ...this.cancelReasons,
          cancelReason
        ];
        this.newCancelReasonDescription = null;
        this.alert = {
          type: 'info',
          message: this.$i18n.t('cancel_reason_successfully_added')
        }
      } catch (error) {
        console.log('DEBUG: onSubmit', error);
        this.alert = {
          type: 'error',
          message: this.$i18n.t('default_error_message')
        }
      }
      this.loading = false;
    },
    async removeCancelReasonById(id) {
      this.alert = {
        type: 'info',
        message: null
      }

      const { isOk } = await this.$dialog.confirm(
        this.$i18n.t('are_you_sure'),
        "",
        "info"
      );

      if (!isOk) {
        return
      }

      this.loading = true;
      try {
        await remove(id);
        this.alert = {
          type: 'info',
          message: this.$i18n.t('cancel_reason_successfully_deleted')
        }
        this.cancelReasons = reject(this.cancelReasons, { id });
      } catch (error) {
        console.log('DEBUG: removeCancelReasonById', error);
        this.alert = {
          type: 'error',
          message: this.$i18n.t('default_error_message')
        }
      }
      this.loading = false;
    }
  }
};
</script>

